<template>
  <div>
      <login/>
  </div>
</template>

<script>
export default {
    name: 'Login',
    components: {
        login: () => import('@/components/login/LoginComponent')
    }

}
</script>

<style>

</style>